var e = {
  "": ["<em>", "</em>"],
  _: ["<strong>", "</strong>"],
  "*": ["<strong>", "</strong>"],
  "~": ["<s>", "</s>"],
  "\n": ["<br />"],
  " ": ["<br />"],
  "-": ["<hr />"]
};
function n(e) {
  return e.replace(RegExp("^" + (e.match(/^(\t| )+/) || "")[0], "gm"), "");
}
function r(e) {
  return (e + "").replace(/"/g, "&quot;").replace(/</g, "&lt;").replace(/>/g, "&gt;");
}
export default function t(a, c) {
  var o,
    l,
    g,
    s,
    p,
    u = /((?:^|\n+)(?:\n---+|\* \*(?: \*)+)\n)|(?:^``` *(\w*)\n([\s\S]*?)\n```$)|((?:(?:^|\n+)(?:\t|  {2,}).+)+\n*)|((?:(?:^|\n)([>*+-]|\d+\.)\s+.*)+)|(?:!\[([^\]]*?)\]\(([^)]+?)\))|(\[)|(\](?:\(([^)]+?)\))?)|(?:(?:^|\n+)([^\s].*)\n(-{3,}|={3,})(?:\n+|$))|(?:(?:^|\n+)(#{1,6})\s*(.+)(?:\n+|$))|(?:`([^`].*?)`)|(  \n\n*|\n{2,}|__|\*\*|[_*]|~~)/gm,
    m = [],
    h = "",
    i = c || {},
    d = 0;
  function f(n) {
    var r = e[n[1] || ""],
      t = m[m.length - 1] == n;
    return r ? r[1] ? (t ? m.pop() : m.push(n), r[0 | t]) : r[0] : n;
  }
  function $() {
    for (var e = ""; m.length;) e += f(m[m.length - 1]);
    return e;
  }
  for (a = a.replace(/^\[(.+?)\]:\s*(.+)$/gm, function (e, n, r) {
    return i[n.toLowerCase()] = r, "";
  }).replace(/^\n+|\n+$/g, ""); g = u.exec(a);) l = a.substring(d, g.index), d = u.lastIndex, o = g[0], l.match(/[^\\](\\\\)*\\$/) || ((p = g[3] || g[4]) ? o = '<pre class="code ' + (g[4] ? "poetry" : g[2].toLowerCase()) + '"><code' + (g[2] ? ' class="language-' + g[2].toLowerCase() + '"' : "") + ">" + n(r(p).replace(/^\n+|\n+$/g, "")) + "</code></pre>" : (p = g[6]) ? (p.match(/\./) && (g[5] = g[5].replace(/^\d+/gm, "")), s = t(n(g[5].replace(/^\s*[>*+.-]/gm, ""))), ">" == p ? p = "blockquote" : (p = p.match(/\./) ? "ol" : "ul", s = s.replace(/^(.*)(\n|$)/gm, "<li>$1</li>")), o = "<" + p + ">" + s + "</" + p + ">") : g[8] ? o = '<img src="' + r(g[8]) + '" alt="' + r(g[7]) + '">' : g[10] ? (h = h.replace("<a>", '<a href="' + r(g[11] || i[l.toLowerCase()]) + '">'), o = $() + "</a>") : g[9] ? o = "<a>" : g[12] || g[14] ? o = "<" + (p = "h" + (g[14] ? g[14].length : g[13] > "=" ? 1 : 2)) + ">" + t(g[12] || g[15], i) + "</" + p + ">" : g[16] ? o = "<code>" + r(g[16]) + "</code>" : (g[17] || g[1]) && (o = f(g[17] || "--"))), h += l, h += o;
  return (h + a.substring(d) + $()).replace(/^\n+|\n+$/g, "");
}
